// Chakra imports
import { Text, useColorModeValue } from "@chakra-ui/react"
import React from "react"

// Assets
import Project1 from "assets/img/profile/Project1.png"
import Project2 from "assets/img/profile/Project2.png"
// Custom components
import Card from "components/card/Card.js"
import Project from "views/admin/profile/components/Project"

export default function Projects(props) {
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white")
  const textColorSecondary = "gray.400"
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  )
  return (
    <Card bg="transparent" mb={{ base: "0px", "2xl": "20px" }}>
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='2xl'
        mt='10px'
        mb='4px'>
        Company X
      </Text>
      <Text color={textColorSecondary} fontSize='md' me='26px' mb='40px'>
        Follow the installation guides the light-weight and easy to use EdgePulse SDK
      </Text>
      <Project
        boxShadow={cardShadow}
        mb='20px'
        image={Project1}
        ranking='1'
        link='https://edgepulseio.github.io/docs/iOS/installation-guide'
        title='iOS Installation Guide'
      />
      <Project
        boxShadow={cardShadow}
        mb='20px'
        image={Project2}
        ranking='2'
        link='https://edgepulseio.github.io/docs/Android/installation-guide'
        title='Android Installation Guide'
      />
    </Card>
  )
}
