// Chakra Imports
import { Avatar, Flex, Icon, Menu, MenuButton, MenuItem, MenuList, Text, useColorModeValue } from '@chakra-ui/react'
import PropTypes from 'prop-types'
// Assets
import { FaMapPin } from 'react-icons/fa'

// Custom Components
import { SidebarResponsive } from 'components/sidebar/Sidebar'
import routes from 'routes.js'

export default function HeaderLinks(props) {
	const { secondary } = props
	// Chakra Color Mode
	let menuBg = useColorModeValue('white', 'navy.800')
	const textColor = useColorModeValue('secondaryGray.900', 'white')
	const ethColor = useColorModeValue('gray.700', 'white')
	const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)')
	const ethBox = useColorModeValue('white', 'navy.800')
	const shadow = useColorModeValue(
		'14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
		'14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
	)
	const bgHover = useColorModeValue(
	  { bg: "secondaryGray.400" },
	  { bg: "whiteAlpha.50" }
	)
	const bgFocus = useColorModeValue(
	  { bg: "secondaryGray.300" },
	  { bg: "whiteAlpha.100" }
	)
	return (
		<Flex
			w={{ sm: '100%', md: 'auto' }}
			alignItems="center"
			flexDirection="row"
			bg={useColorModeValue('rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 0)')}
			flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
			p="10px"
			boxShadow={useColorModeValue('rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 0)')}>
			<Menu>
			<MenuButton p="0px">
			<Flex
				bg={bgFocus}
				display='flex'
				borderRadius="30px"
				_hover={bgHover}
				_focus={bgFocus}
				_active={bgFocus}
				ms="auto"
				p="6px"
				align="center"
				me="6px">
				<Flex align="center" justify="center" bg={ethBox} h="29px" w="29px" borderRadius="30px" me="7px">
					<Icon color={ethColor} w="9px" h="14px" as={FaMapPin} />
				</Flex>
				<Text w="max-content" color={ethColor} fontSize="lg" fontWeight="800" me="6px">
				Hartsfield-Jackson Atlanta
				</Text>
			</Flex>
			</MenuButton>
				<MenuList boxShadow={shadow} p="0px" mt="10px" borderRadius="20px" bg={menuBg} border="none">
					<Flex w="100%" mb="0px">
						<Text
							ps="20px"
							pt="16px"
							pb="10px"
							pr="20px"
							w="100%"
							fontSize="sm"
							fontWeight="700"
							color={textColor}>
							Hartsfield-Jackson Atlanta (GA)
						</Text>
					</Flex>
					<Flex flexDirection="column" p="10px">
						<MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius="0px" px="14px">
							<Text fontSize="sm">John F. Kennedy (NY)</Text>
						</MenuItem>
						<MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius="0px" px="14px">
							<Text fontSize="sm">Dallas-Fort Worth (TX)</Text>
						</MenuItem>
					</Flex>
				</MenuList>
			</Menu>
			<SidebarResponsive routes={routes} />
			<Menu>
				<MenuButton p="0px">
					<Avatar
						_hover={{ cursor: 'pointer' }}
						color="white"
						name="Adela Parkson"
						bg="#11047A"
						size="sm"
						w="40px"
						h="40px"
					/>
				</MenuButton>
				<MenuList boxShadow={shadow} p="0px" mt="10px" borderRadius="20px" bg={menuBg} border="none">
					<Flex w="100%" mb="0px">
						<Text
							ps="20px"
							pt="16px"
							pb="10px"
							w="100%"
							borderBottom="1px solid"
							borderColor={borderColor}
							fontSize="sm"
							fontWeight="700"
							color={textColor}>
							👋&nbsp; Hey, Adela
						</Text>
					</Flex>
					<Flex flexDirection="column" p="10px">
						<MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius="8px" px="14px">
							<Text fontSize="sm">Profile Settings</Text>
						</MenuItem>
						<MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius="8px" px="14px">
							<Text fontSize="sm">Newsletter Settings</Text>
						</MenuItem>
						<MenuItem
							_hover={{ bg: 'none' }}
							_focus={{ bg: 'none' }}
							color="red.400"
							borderRadius="8px"
							px="14px">
							<Text fontSize="sm">Log out</Text>
						</MenuItem>
					</Flex>
				</MenuList>
			</Menu>
		</Flex>
	)
}

HeaderLinks.propTypes = {
	variant: PropTypes.string,
	fixed: PropTypes.bool,
	secondary: PropTypes.bool,
	onOpen: PropTypes.func
}
