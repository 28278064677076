import { TimeIcon } from "@chakra-ui/icons"
import { Button, Input, InputGroup, InputLeftElement, Menu, MenuButton, MenuItemOption, MenuList, MenuOptionGroup } from '@chakra-ui/react'

import { AntennaIcon, ThermometerIcon, WifiIcon } from "components/icons/Icons"

function FilterMenu() {
//   const [timeRange, setTimeRange] = useState('1') // default value as per your requirement
//   const [status, setStatus] = useState([])
//   const [transport, setTransport] = useState([])
//   const [selectedPeriod, setSelectedPeriod] = useState("1d")

//   const handlePeriodChange = (event) => {
//     setSelectedPeriod(event.target.value)
//   }

//   const [show, setShow] = useState(false)

//   const [selectedOption, setSelectedOption] = useState("1d")

//   const handleOptionChange = (event) => {
//     setSelectedOption(event.target.value)
//   }

  return (
    <Menu closeOnSelect={false}>
      <InputGroup>
        <InputLeftElement pointerEvents='none'>
          <TimeIcon color='black' />
        </InputLeftElement>
        <Input type='period' placeholder='1d | 4d | 1w | 1m' />
      </InputGroup>

      <Menu closeOnSelect={false}>
      <MenuButton as={Button} color='gray.300' leftIcon={<ThermometerIcon color='gray.300' />}>
        All Pulses
      </MenuButton>
      <MenuList>
        <MenuOptionGroup type='checkbox'>
          <MenuItemOption value='good'>Good</MenuItemOption>
          <MenuItemOption value='ok'>Ok</MenuItemOption>
          <MenuItemOption value='bad'>Bad</MenuItemOption>
        </MenuOptionGroup>
      </MenuList>
      </Menu>

      <Menu closeOnSelect={false}>
      <MenuButton as={Button} color='gray.300' leftIcon={<><WifiIcon color='gray.300'/><AntennaIcon color='gray.300'/></>}>
        Cellular | Wifi
      </MenuButton>
      <MenuList>
        <MenuOptionGroup type='checkbox'>
          <MenuItemOption value='wifi'>Wifi</MenuItemOption>
          <MenuItemOption value='cellular'>Cellular</MenuItemOption>
        </MenuOptionGroup>
      </MenuList>
      </Menu>

    </Menu>
  )
}

export default FilterMenu
