/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _|
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|

=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, Flex, Grid } from "@chakra-ui/react"

// Assets
import avatar from "assets/img/avatars/logo_stub.jpg"
import Card from "components/card/Card.js"
import tableDataDevelopment from "views/admin/dataTables/variables/tableDataDevelopment.json"
import { tableColumnsTopCreators } from "views/admin/mapping/variables/tableColumnsTopCreators"
import tableDataTopCreators from "views/admin/mapping/variables/tableDataTopCreators.json"
// Custom components
import { columnsDataDevelopment, columnsDataLocation } from "views/admin/profile/components/columnsData"
import DevelopmentTable from "views/admin/profile/components/DevelopmentTable"
import LocationTable from "views/admin/profile/components/LocationTable"
import Projects from "views/admin/profile/components/Projects"
import tableDataLocaton from "views/admin/profile/components/tableDataLocation.json"
import TableTopCreators from "views/admin/profile/components/TableTopCreators"

import DetailsCard from "./components/DetailsCard"

export default function Overview() {

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Flex
        direction={{ base: "column", md: "row" }}
        align="center"
        justify="start"
        p="20px"
        rounded="lg"
        mb="20px"
        bg="transparent"
        gap={{ base: "20px", md: "40px" }}
      >
        <Flex direction="column" bg="transparent" align="center" pl={{ md: "20px" }}>
        <Projects
          gridArea='1 / 2 / 2 / 2'
          banner={avatar}
          avatar={avatar}
          name='Adela Parkson'
          job='Product Designer'
          posts='17'
          followers='9.7k'
          following='274'
        />
        </Flex>
        {/* <Flex direction="column" textAlign="left">
          <DetailsCard/>
        </Flex> */}

      </Flex>

    </Box>
  )
}
