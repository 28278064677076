import { Icon } from "@chakra-ui/react"
import { MdDeviceHub, MdEdgesensorHigh, MdHome, MdMap, MdOutlineChat } from "react-icons/md"

// Admin Imports
import DataTables from "views/admin/dataTables"
import MainDashboard from "views/admin/default"
import NFTMarketplace from "views/admin/mapping"
import Profile from "views/admin/profile"

const createIcon = (IconComponent) => {
    return <Icon as={IconComponent} width='20px' height='20px' color='inherit' />
}

const routeDetails = [
  {
    name: "Dashboard",
    icon: createIcon(MdHome),
    path: "default",
    component: Profile,
  },
  {
    name: "Performance",
    icon: createIcon(MdEdgesensorHigh),
    // component: MainDashboard,
    secondary: true,
  },
  {
    name: "Connectivity Map",
    icon: createIcon(MdMap),
    component: NFTMarketplace,
    secondary: true,
  },
  {
    name: "Chat",
    icon: createIcon(MdOutlineChat),
    // component: DataTables,
  },
  {
    name: "Agent Edge",
    icon: createIcon(MdDeviceHub),
    // component: DataTables,
  },
]

const routes = routeDetails.map(route => {
    const path = route.path || route.name.toLowerCase().replace(/\s+/g, '-')
    return {
        layout: "/admin",
      ...route,
      path: `/${path}`,
    }
})

export default routes
