import { useTheme, withAuthenticator } from '@aws-amplify/ui-react'
import { Box, Button, Center, ChakraProvider, Flex, Image, Text } from '@chakra-ui/react'
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui'
import { Amplify } from 'aws-amplify'
import { post } from 'aws-amplify/api'
import { fetchAuthSession, fetchUserAttributes, updateUserAttribute } from 'aws-amplify/auth'
import { Cache, Hub } from 'aws-amplify/utils'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import logo from "assets/img/logo.png"
import { LogoutIcon } from 'components/icons/Icons'
import AdminLayout from 'layouts/admin'
import theme from 'theme/theme'

import awsConfig from './aws-exports'

import '@aws-amplify/ui-react/styles.css'
import 'assets/css/App.css'
import 'mapbox-gl/dist/mapbox-gl.css'

const location = `${window.location.origin}/`
const oauth = awsConfig.oauth
oauth.redirectSignIn = oauth.redirectSignOut = location

Amplify.configure(awsConfig, {
    API: {
      REST: {
        headers: async () => {
            const authToken = (await fetchAuthSession()).tokens?.idToken?.toString()
            return { Authorization: authToken }
        }
      }
    }
})

const getTenantIdFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get('tenantId')
}

async function handleEvent(data) {
    const userAttributes = await fetchUserAttributes()
    const attributeKey = 'custom:tenantId'
    const tenantId = userAttributes[attributeKey]
    let value = getTenantIdFromUrl() || uuidv4()

    if (!tenantId) {
        // This means the attribute does not exist, treat it as a signup
        console.log('User signed up:', data)
        console.log('Storing tenantId as user attribute:', value)

        await updateUserAttribute({
            userAttribute: {
                attributeKey,
                value
            }
        })

        console.log('Generate api key for tenantId')

        console.log("POST /apikeys")
        const postCall = post({
            apiName: 'portalapi',
            path: '/apikeys'
        })
        const { body } = await postCall.response
        console.log("BODY: ", body)
        const response = await body.json()
        console.log('REST POST call succeeded: ', response)
    }
    else {
        value = tenantId
    }

    console.log(`Caching tenantId with value ${value}`)
    Cache.setItem('tenantId', value)
}

const events = ['signedUp', 'signedIn']

Hub.listen('auth', async ({ payload: { event, data } }) => {
    console.log('Auth event:', event, data)
    if (events.includes(event)) {
        handleEvent(data)
    }
})

const components = {
    Header() {
      const { tokens } = useTheme()

      return (
        <Flex>
          <Box width="100px">
            <Image
              alt="EdgePulse"
              src={logo}
              style={{
                  width: 100, // Original width (1000) / 20 = 50
                  height: 100 // Original height (1000) / 20 = 50
                }}
            />
          </Box>
          <Center justifyContent="flex-start" style={{ marginLeft: 20 }}>
          <Text style={{ color: "#0070ff", fontSize: 24, fontWeight: "bold" }}>E d g e P u l s e</Text>
          </Center>
        </Flex>
      )
    }
}

const App = ({ signOut }) => {
    return (
        <ChakraProvider theme={theme}>
            <ThemeEditorProvider>
              <HashRouter>
                <Switch>
                  <Route path={`/admin`} component={AdminLayout} />
                  <Redirect from='/' to='/admin' />
                </Switch>
              </HashRouter>
              <Button onClick={signOut} leftIcon={<LogoutIcon color='gray.300' />}>Sign out</Button>
            </ThemeEditorProvider>
        </ChakraProvider>
    )
}

const AppWithAuth = withAuthenticator(App, {components})

createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <AppWithAuth />
  </React.StrictMode>
)
