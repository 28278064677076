// Chakra imports
import { Box, Flex, Link, Text, useColorModeValue } from "@chakra-ui/react"

// Custom components
import Card from "components/card/Card.js"
// Assets
import { AndroidLogo, AppleLogo } from "components/icons/Icons"

export default function Project(props) {
  const { title, ranking, link, image, ...rest } = props
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white")
  const textColorSecondary = "gray.400"
  const brandColor = useColorModeValue("brand.500", "white")
  const bg = useColorModeValue("white", "navy.700")
  const iosColor = "blue.500"; // example color for iOS
  const androidColor = "green.500"; // example color for Android

  return (
    <Card bg={bg} {...rest} p='14px'>
      <Flex align='center' direction={{ base: "column", md: "row" }}>
      {ranking === '1' ? (
          <AppleLogo
            color={iosColor}
            me='40px'
            h='60px'
            w='60px'
          />
        ) : (
          <AndroidLogo
            color={androidColor}
            me='40px'
            h='60px'
            w='60px'
          />
        )}
        <Box mt={{ base: "10px", md: "0" }}>
          <Text
            color={textColorPrimary}
            fontWeight='1000'
            fontSize='md'
            mb='4px'>
            {title}
          </Text>
          <Text
            fontWeight='500'
            color={textColorSecondary}
            fontSize='sm'
            me='4px'>
            <Link fontWeight='500' color={brandColor} href={link} fontSize='sm'>
              Get Started
            </Link>
          </Text>
        </Box>
      </Flex>
    </Card>
  )
}
