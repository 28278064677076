/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _|
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|

=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { Box, SimpleGrid } from "@chakra-ui/react"
import { parse } from 'date-fns'
import { useEffect, useMemo, useState } from "react"
import MapGL, { Layer, Source } from "react-map-gl"

import FilterMenu from "components/menu/FilterMenu"

import { heatmapLayer } from "./heatmap"

const MAPBOX_TOKEN = "pk.eyJ1IjoibnBiYWxsYXJkMTEiLCJhIjoiY2xraDl0d2l0MDZ6bDNlb2h2MnlpMTkxYSJ9.l6qs4UL8ULs2BAoGiqzHZw" // Set your mapbox token here

function filterFeaturesByDay(featureCollection, time) {
  const date = new Date(time)
  const year = date.getFullYear()
  const month = date.getMonth()
  const day = date.getDate()
  const features = featureCollection.features.filter(feature => {
    const featureDate = new Date(feature.properties.time)
    return (
      featureDate.getFullYear() === year &&
      featureDate.getMonth() === month &&
      featureDate.getDate() === day
    )
  })
  return {type: "FeatureCollection", features}
}

export default function Marketplace() {
  const [allDevices, setAllDevices] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [timeRange, setTimeRange] = useState([0, 0])
  const [selectedTime, selectTime] = useState(new Date())
  const [strengthDevices, setStrengthDevices] = useState(null)

  /*
  useEffect(() => {
    fetch("/data.json",{headers : {"Content-Type": "application/json", "Accept": "application/json"}})
      .then(resp => resp.json())
      .then(json => {
        // Note: In a real application you would do a validation of JSON data before doing anything with it,
        // but for demonstration purposes we ignore this part here and just try to select needed data...
        setAllDevices(json.features)
        const groupedFeatures = {}
        var startTime, endTime
        json.features.forEach(feature => {
            const id = feature.properties.id
            const time = feature.properties.time
            if (!groupedFeatures[id] || time > groupedFeatures[id].properties.time) {
                groupedFeatures[id] = feature
            }
            if(!startTime || time < startTime) { startTime = time }
            if(!endTime || time > endTime) { endTime = time }
        })

        const filteredGeoJSON = {type: 'FeatureCollection', features: Object.values(groupedFeatures)}

        setTimeRange([startTime, endTime])
        setStrengthDevices(filteredGeoJSON)
        selectTime(new Date(endTime))
      })
      .catch(err => console.error("Could not load data", err)) // eslint-disable-line
  }, [])

  const handleChange = (event) => {
    const value = event.target.value
    const parsedDateTime = parse(value, "yyyy-MM-dd'T'HH:mm", new Date())

    if (!isNaN(parsedDateTime.getTime())) {
      selectTime(parsedDateTime)

    }
  }

  useMemo(() => {
    if (allDevices == null) { return }
    const groupedFeatures = {}
    allDevices.forEach(feature => {
        const id = feature.properties.id
        const time = feature.properties.time
        if (!groupedFeatures[id] || (time > groupedFeatures[id].properties.time && time <= selectedTime) ) {
            groupedFeatures[id] = feature
        }
    })
    const filteredGeoJSON = {type: 'FeatureCollection', features: Object.values(groupedFeatures)}
    setStrengthDevices(filteredGeoJSON)
  }, [selectedTime])

  const data = useMemo(() => {
    if (allDevices == null) { return }
    let filterByStrength
    switch(selectedStatus)
    {
        case "red":
            filterByStrength = (feature) => feature.properties.strength < 5
            break
        case "yellow":
            filterByStrength =(feature) => 5 <= feature.properties.strength && feature.properties.strength < 7
            break
        case "green":
            filterByStrength = (feature) => feature.properties.strength >= 7
            break
        default: // all
            return strengthDevices
    }
    return {type: "FeatureCollection", features: strengthDevices.features.filter(filterByStrength)}
  }, [selectedStatus, strengthDevices])
  */

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
       <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 7 }}
        gap="20px"
        mb="20px">
        <FilterMenu/>
      </SimpleGrid>
      <Box w="100%" h="100vh">
        <MapGL
        initialViewState={{
            // latitude: 40.6413, // JFK airport latitude
            // longitude: -73.7781, // JFK airport longitude
          latitude: 33.6320, // Atlanta airport latitude
          longitude: -84.4277, // Atlanta airport longitude
          zoom: 13, // Adjust this to your desired initial zoom level
        //   scrollZoom: false, // Disables mousewheel zoom
          scrollZoom: true, // Disables mousewheel zoom
          touchZoom: false, // Disables touch zoom (for touch devices)
          doubleClickZoom: false, // Disables zoom on double click
          boxZoom: false, // Disables zoom using the Shift key + drag
          dragRotate: false, // Disables map rotation
          keyboard: false,
          attributionControl: false  // Disables all keyboard interactions including zoom
        }}
        // mapStyle="mapbox://styles/npballard11/clo8y6xje00ot01pd0ffe2lad"
        mapStyle="mapbox://styles/mapbox/light-v10"
        mapboxAccessToken={MAPBOX_TOKEN}
      >
        {/* for now we don't wanna draw anything
        {data && (
          <Source type="geojson" data={data}>
            <Layer {...heatmapLayer} />
          </Source>
        )}
        */}
      </MapGL>
    </Box>
    </Box>
  )
}
